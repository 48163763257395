import React from 'react'
import PropTypes from 'prop-types'

const OneColumnPageLayout = ({ children }) => (
  <div className='bg-white py-12 md:pt-12 md:pb-16'>
    <div className='w-11/12 lg:w-9/12 xl:w-8/12 xl:max-w-screen-md mx-auto'>
      {children}
    </div>
  </div>
)

OneColumnPageLayout.propTypes = {
  children: PropTypes.node,
}

export default OneColumnPageLayout